/**
 * This module is designed to be used with sw-api-store. The core SwApiStore
 * catches any thrown SwThrottledError during updates.
 */

export class SwThrottledError extends Error {
  constructor(description) {
    super(`Request throttled: ${description}`);
    this.name = "SwThrottledError";
  }
}

/**
 * Takes a function which should return a Date object. Returns a function which
 * will return a promise that resolves after the given date. If the function is
 * called again before the promise resolves, the promise will be rejected.
 *
 * @param {() => Date} fNextTime - The time when the promise should resolve.
 * @param {string} [description] - For raised exception bookkeeping, does not
 *   affect behaviour.
 * @returns {() => Promise<void>}
 */
const delayer = (fNextTime, description) => {
  let timeoutId = null;
  let lastReject = null;

  return async () => {
    const next = fNextTime();
    const now = Date.now();

    // Instantly resolve if there is no duration
    if (next - now <= 0) {
      timeoutId = null;
      lastReject = null;
      return Promise.resolve();
    }

    // cancel resolution timeout + reject previous promise
    if (timeoutId) clearTimeout(timeoutId);
    if (lastReject) {
      lastReject(new SwThrottledError(description));
    }

    // recreate promise
    return new Promise((resolve, reject) => {
      lastReject = reject;
      timeoutId = setTimeout(() => {
        timeoutId = null;
        lastReject = null;
        resolve();
      }, next - now);
    });
  };
};

// Waits for durationMs before resolving, aborts previous calls if called again
export const debouncer = (durationMs) => {
  if (durationMs <= 0) return () => Promise.resolve();
  return delayer(
    () => Date.now() + durationMs,
    `Another request received within debounce window of ${durationMs}ms`,
  );
};

// Allows 1 request per durationMs, aborting previous calls - last request always wins
export const rateLimiter = (durationMs) => {
  if (durationMs <= 0) return () => Promise.resolve();

  return delayer(() => {
    const at = durationMs * Math.ceil((Date.now() + 0) / durationMs);
    return at;
  }, `API Store rate limit of ${durationMs}ms exceeded`);
};

// Enforce order if multiple requests in flight
export const Orderer = (asyncFunc) => {
  const queue = [];

  // Some notes on the implementation, perhaps just for me.  It's
  // important to bear in mind we are *substituting* the original
  // async function.  The new function which returns a promise which
  // is NOT chained in any way with the original. Any communication
  // between INNER and OUTER is done by calling resolve/reject.
  const inOrder = async (...args) =>
    new Promise((resolve, reject) => {
      // Queue marks our position in the order and we can use the
      // content to reject the OUTER promise if things get out of
      // order.
      queue.push(reject);

      // Now we fire the original async function. It is not chained to
      // the outer promise.  We will resolve the outer function with
      // original's return value only if it satisfies our ordering
      // constraints.
      asyncFunc(...args).then(
        (data) => {
          // The inner promise has resolved. Where are we in the queue?
          const i = queue.indexOf(reject);

          if (i >= 0) {
            queue.splice(0, i).forEach((rej) => {
              // If we're not first in the queue reject the
              // corresponding OUTER promises. Very important to note
              // That the corresponding INNER promise can still
              // resolve and invoke this `.then` function, and we just
              // spliced its reject.
              rej(
                new SwThrottledError("Response received not in request order"),
              );
            });

            // remove this INNER's reject function as we've just resolved.
            queue.shift();
            resolve(data);
          }
          // as described. If i < 0, it means we are an INNER promise
          // that resolved, with an OUTER promise that was already
          // rejected.
        },
        (err) => {
          // inner promise rejected some reason. Call reject and allow
          // SwApiStore to continue error handling.
          const i = queue.indexOf(reject);
          if (i > -1) {
            queue.splice(i, 1);
          }
          reject(err);
        },
      );
    });

  const reset = () => {
    queue.splice(0, queue.length).forEach((rej) => {
      rej(new SwThrottledError("Request cancelled by reset"));
    });
  };

  return {
    inOrder,
    reset,
    get length() {
      return queue.length;
    },
  };
};
