import * as Sentry from "@sentry/browser";
import { ApiError } from "../../../../svelte/api/sw-api-error";

const browser = (() => {
  const test = (regexp) => regexp.test(window.navigator.userAgent);
  switch (true) {
    case test(/edg/i):
      return "Microsoft Edge";
    case test(/trident/i):
      return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i):
      return "Mozilla Firefox";
    case test(/opr\//i):
      return "Opera";
    case test(/ucbrowser/i):
      return "UC Browser";
    case test(/samsungbrowser/i):
      return "Samsung Browser";
    case test(/chrome|chromium|crios/i):
      return "Google Chrome";
    case test(/safari/i):
      return "Apple Safari";
    default:
      return "Other";
  }
})();

const REDIRECT_BROWSERS = [
  "Microsoft Internet Explorer",
  "Mozilla Firefox",
  "Opera",
  "UC Browser",
  "Samsung Browser",
  "Apple Safari",
];
const EXCLUDED_FROM_REDIRECT = [
  "/deliverability/report",
  "/onboarding/signup",
  "/onboarding/contract",
  "/onboarding/payment",
  "/onboarding/success",
  "/onboarding/error",
];

const imgNotFound = (e) => {
  $(e.target).unbind("error").attr("src", "/assets/img/icons/user.png");
};

const initSentry = () => {
  const environment =
    window.location.host === "sourcewhale.app"
      ? "production"
      : window.location.host.split(".")[0];
  if (environment.split(":")[0] === "localhost") {
    console.warn("Not configuring Sentry for localhost");
    return;
  }

  // eslint-disable-next-line no-undef
  const release = SENTRY_RELEASE_NAME; // provided at build-time by webpack

  Sentry.init({
    dsn: "https://676c3a1b0ae44248b1507b900f6b8c1e@o358760.ingest.sentry.io/5260818",
    environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
    ],
    tracesSampleRate: 0.01,
    ignoreErrors: ["Non-Error exception captured"],
    release,
    beforeSend: (event, hint) => {
      const error = hint.originalException;
      const apiError = [error, error?.cause].find((e) => e instanceof ApiError);
      if (apiError?.status === 401) {
        return null;
      }

      return event;
    },
    denyUrls: [/https?:\/\/((cdn|www)\.)?birdie\.so/, /chrome-extension:\/\//],
  });

  console.log("Sentry configured for", environment, "release", release);
};

export const initializeConfig = () => {
  if (
    REDIRECT_BROWSERS.includes(browser) &&
    !EXCLUDED_FROM_REDIRECT.includes(window.location.pathname)
  )
    window.location = "/signin/browser-redirect";

  $(".photo-image").on("error", imgNotFound);

  $(document).ajaxComplete(() => {
    $(".photo-image").on("error", imgNotFound);
  });

  initSentry();
};
