import { derived } from "svelte/store";
import { getTodos, getUser } from "../api/users.js";
import { SwApiStore } from "./sw-api-store.js";

export const userStore = SwApiStore({
  get: () => getUser(),
  onMessage: ({ message, update }) => {
    if (message.kind !== "update-teams-credit-history") return;
    message.changes.forEach((change) => {
      update((currentStore) => ({ ...currentStore, ...change.update }));
    });
  },
  cacheKey: "user",
});

export const userHasDiallerStore = derived(
  [userStore],
  ([$userStore]) =>
    $userStore.kind !== "viewer" &&
    ["standard", "unlimited"].includes($userStore.diallerPlan),
);

export const userTodosStore = SwApiStore({
  get: () => getTodos({}),
  onMessage: ({ message }) => {
    if (!["update-to-dos"].includes(message.kind)) return;
    userTodosStore.get();
  },
  cacheKey: "userTodos",
});

export const userHasContactPanelAIStore = derived(
  [userStore],
  ([$userStore]) => $userStore.contactPanelAI,
);
