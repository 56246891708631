import { ApiError } from "./sw-api-error";
import { ToastError } from "./sw-toast-error";

/**
 * @typedef {Object} Request
 * @property {string} path - The API endpoint path.
 * @property {Object<string, string>} [params] - The query parameters for the
 *   request.
 * @property {Object} [body] - The body of the request, used for POST or PUT
 *   methods.
 * @property {"GET" | "POST" | "PUT"} [method] - The HTTP method to use for the
 *   request.
 */

const url = (apiCall) => {
  const queryString =
    apiCall.params && Object.keys(apiCall.params).length
      ? `?${new URLSearchParams(apiCall.params).toString()}`
      : "";
  return `${apiCall.path}${queryString}`;
};

/**
 * Makes an API request.
 *
 * @template T
 * @param {Request} apiCall - The details of the API call.
 * @param {AbortSignal} signal - The signal to use for the request.
 * @returns {Promise<T>} A promise that resolves to the response data.
 * @throws {ApiError} Throws an ApiError if the request fails.
 */
export const swRequest = async (apiCall, { signal } = {}) => {
  try {
    const response = await fetch(url(apiCall), {
      credentials: "include",
      body: apiCall.body ? JSON.stringify(apiCall.body) : undefined,
      method: apiCall.method || "GET",
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    });

    if (!response.ok) {
      const text = await response.text();
      throw new ApiError(response.status, text);
    }
    const jsonData = await response.json();
    if (signal?.aborted) {
      // Request could be aborted after fetch is complete but before
      // data is read so do final check.
      throw DOMException("Request aborted after JSON reading", "AbortError");
    }
    return jsonData;
  } catch (err) {
    if (err instanceof ApiError && err.status === 401) {
      localStorage.removeItem("provider");
      const { pathname, search } = window.location;
      if (!pathname.includes("/signin"))
        window.location.replace(`/signin?redirect=${pathname}${search}`);
    }
    throw new ToastError("An error occurred", { cause: err });
  }
};
