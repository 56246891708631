import { getSessionKey } from "../../assets/js/src/general/websocket.js";
import { swRequest } from "./sw-request.js";

export const getUser = () =>
  swRequest({
    path: "/api/users/get",
    params: {
      sessionKey: getSessionKey(),
      href: window.location.href,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

export const getUsers = (params) =>
  swRequest({
    path: "/api/users/get_all",
    method: "GET",
    params,
  });

export const getTodos = (params, opts = {}) =>
  swRequest(
    {
      path: "/api/users/get_todos",
      method: "GET",
      params,
    },
    opts,
  );
