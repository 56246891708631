import { ApiError } from "./sw-api-error";

export class ToastError extends Error {
  constructor(message, opts = {}) {
    super(message, opts);
    this.name = "ToastError";
    this.message = message;

    this.subtitle = "";
    this.title = "An error occurred";
    this.type = "danger";

    const { cause } = opts;

    // It's worth noting that while all fetch errors raise a
    // ToastError, they can be generically raised by other code so
    // useful to be very specific before overriding messages.
    if (
      (cause instanceof TypeError && cause.message === "Failed to fetch") ||
      (cause instanceof ApiError && cause.status === 0)
    ) {
      this.title = "A network error occurred";
      this.subtitle = "Please check your internet connection and try again.";
    } else if (cause instanceof ApiError && cause.status === 401) {
      this.title = "Your session has expired";
      this.subtitle = "Redirecting to signin page.";
    } else if (cause instanceof ApiError && [400, 402].includes(cause.status)) {
      try {
        this.subtitle = JSON.parse(cause.message);
      } catch {
        this.subtitle = "";
      }
    }
  }
}
