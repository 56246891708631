import { writable } from "svelte/store";
import { showToast } from "../../assets/js/src/general/utils.js";
import { addWebsocketListener } from "../../assets/js/src/general/websocket.js";
import { ApiError } from "../api/sw-api-error.js";
import { ToastError } from "../api/sw-toast-error.js";
import {
  debouncer,
  Orderer,
  rateLimiter,
  SwThrottledError,
} from "./sw-throttling.js";

const MetaStore = () => {
  let data = {
    fetching: false, // For spinners
    firstFetchDone: false, // For skeleton loading / once
  };

  const subscribers = new Set();
  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  const set = (newData) => {
    data = newData;
    subscribers.forEach((subscriber) => subscriber(data));
  };

  const update = (updater) => {
    data = updater(data);
    subscribers.forEach((subscriber) => subscriber(data));
  };

  return {
    subscribe,
    set,
    update,

    // utility methods to access the live data without subscribing. If
    // you're looking to set skeleton loading or spinners, you
    // probably want to subscribe to the store itself.
    fetching: () => data.fetching,
    firstFetchDone: () => data.firstFetchDone,
  };
};

/**
 * Fetches data from SourceWhale and puts the result in a form easily accessible
 * from Svelte.
 *
 * You can cache responses between page loads in localStorage by providing a
 * truthy `cacheKey` string before `get` or `once` is called. If `cacheKey` is
 * function it is called to get the key for localStorage.
 *
 * If there is no data in the cache, the `init` function is called to populate
 * the store before the first fetch. It is also the value the store takes when
 * it is reset. You must ensure the method returns something with the same
 * "shape" as the API. For example, if the API returns a list you want
 *
 * `init: () => []`
 *
 * You can save yourself from nullish checks by providing a more specific
 * default, for example the training hub store has
 *
 * `init: () => ({ tasks: [], taskCards: [], totalProgress: {} })`
 *
 * This means we don't have to check whether those fields exist on the store in
 * our Svelte Markup.
 */
export const SwApiStore = ({
  get,
  onMessage,
  init = () => ({}),
  cacheKey,
  rateLimitMs = 0,
  debounceDelayMs = 0,
  concurrencyStrategy = "abortPreviousRequests",
}) => {
  // concurrencyStrategy ensures multiple calls to get() cannot resolve out of order.
  // There are two approaches.
  //
  // "abortPreviousRequests" is the simplest, and cancels the previous request
  // when a new one is made.  The caller must ensure that the get
  // method accepts a second opts argument, which forwards the signal
  // from an AbortController to swRequest (otherwise this does
  // nothing).
  //
  // "skipOutOfOrderResponses" allows multiple requests to be
  // in-flight, but rejects requests that are received out of the
  // order in which they were sent. This one is useful for requests
  // that "stream" a narrowing set of responses in response to user
  // input (e.g. datatables searches).

  if (
    !["abortPreviousRequests", "skipOutOfOrderResponses"].includes(
      concurrencyStrategy,
    )
  ) {
    throw new Error(`Invalid concurrencyStrategy: ${concurrencyStrategy}`);
  }

  const meta = MetaStore();

  const cacheKeyValue = () => {
    if (!cacheKey) return null;
    return typeof cacheKey === "string" ? cacheKey : cacheKey();
  };

  const wrappedInit = () => {
    const key = cacheKeyValue();
    if (key) {
      const cachedData = localStorage.getItem(key);
      if (cachedData) {
        try {
          return JSON.parse(cachedData);
        } catch (e) {
          console.warn("Failed to parse cached data", cachedData, e);
        }
      }
    }
    return init();
  };

  const rateLimit = rateLimiter(rateLimitMs);
  const debounce = debouncer(debounceDelayMs);
  const orderer =
    concurrencyStrategy === "skipOutOfOrderResponses" ? Orderer(get) : null;
  const orderedGet = orderer?.inOrder || get;

  // Store content
  let data = wrappedInit();

  let abortController = null;
  /** @param {[string]} message */
  const cancelFetch = (message) => {
    if (abortController && concurrencyStrategy === "abortPreviousRequests") {
      abortController.abort(new DOMException(message, "AbortError"));
    }
    abortController = null;
  };

  // Implementation of svelte store contract methods
  const subscribers = new Set();
  const subscribe = (subscriber) => {
    subscribers.add(subscriber);
    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  const notifySubscribers = () =>
    subscribers.forEach((subscriber) => subscriber(data));

  // Todo - consider adding meta as an optional second argument to the updater function?
  const update = (updater) => {
    data = updater(data);
    notifySubscribers();
  };

  // We only query this at the end of requests.
  // If we are in abortPreviousMode this can always return false.
  const anyFetchesInFlight = () => (orderer?.length || 0) > 0;

  const getAndUpdateStore = async () => {
    cancelFetch("New request");
    meta.update((value) => ({ ...value, fetching: true }));

    try {
      await rateLimit();
      await debounce();

      abortController = new AbortController();
      const { signal } = abortController;
      const newData = await orderedGet({ signal });
      if (!newData) {
        // should never happen
        throw new Error("No data returned from API");
      }
      if (newData && cacheKey) {
        const key = cacheKeyValue();
        if (key) {
          localStorage.setItem(key, JSON.stringify(newData));
        }
      }
      data = newData;
      notifySubscribers();
      meta.set({ fetching: anyFetchesInFlight(), firstFetchDone: true });
      return data;
    } catch (err) {
      // SwThrottleError means that the request was
      // debounced/rate-limited/out-of-order, which means there is
      // another request coming.  Therefore, for the purposes of any
      // components listening to the meta store state, we want to
      // leave meta.fetching as true.
      if (err instanceof SwThrottledError) {
        return null;
      }
      meta.update((value) => ({ ...value, fetching: anyFetchesInFlight() }));

      if (err instanceof ToastError) {
        // All errors from fetching should throw a ToastError but they
        // can happen for several reasons. We don't always want to show the user.
        const { cause = {} } = err;
        const { name, status } = cause;

        // Don't show if request was aborted by a new request being fired.
        if (cause instanceof DOMException && name === "AbortError") {
          return null;
        }
        // Don't show if we are unauthorized on the signin page
        if (
          cause instanceof ApiError &&
          status === 401 &&
          window.location.pathname.startsWith("/signin")
        ) {
          return null;
        }

        // Otherwise display the message.
        showToast(err);
        return null;
      }

      // uncomment throw and delete console.error+return once we're
      // convinced nothing catastrophic will happen;

      // throw error
      console.error(
        "SwApiStore.getAndUpdateStore: Uncaught error",
        err,
        err.cause,
      );
      return null;
    }
  };

  const once = async () => {
    if (meta.firstFetchDone()) return data;
    if (!meta.fetching()) getAndUpdateStore();
    return new Promise((resolve) => {
      const unsubscribe = subscribe(() => {
        const resolveWhenReady = () => {
          if (meta.firstFetchDone()) {
            unsubscribe();
            resolve(data);
          } else {
            setTimeout(resolveWhenReady, 10);
          }
        };
        resolveWhenReady();
      });
    });
  };

  const reset = () => {
    cancelFetch("api store reset");
    if (orderer) orderer.reset();
    meta.set({ fetching: false, firstFetchDone: false });
    data = wrappedInit();
    notifySubscribers();
  };

  if (onMessage)
    addWebsocketListener((message) =>
      onMessage({ update, get: getAndUpdateStore, message }),
    );

  return {
    subscribe,
    get: getAndUpdateStore,
    once,
    meta: {
      // only surface subscribe method to make read-only.
      subscribe: meta.subscribe,
      fetching: meta.fetching,
      firstFetchDone: meta.firstFetchDone,
    },
    update,
    reset,
  };
};

export const SwDerivedApiStore = ({
  get,
  init,
  onMessage,
  argsStore,
  argsTransform = (x) => x,
  dataTransform = (x) => x,
  resetBeforeFetch = true,
  cacheKey,
  concurrencyStrategy = "abortPreviousRequests",
  rateLimitMs = 0,
  debounceDelayMs = 0,
}) => {
  let args;
  let data;

  // opts included for abort controller signal
  const getWithArgs = (opts) => get(...args, opts);

  const apiStore = SwApiStore({
    get: getWithArgs,
    init,
    onMessage,
    cacheKey,
    concurrencyStrategy,
    rateLimitMs,
    debounceDelayMs,
  });

  const getOrReset = () => {
    if (args) {
      if (resetBeforeFetch) {
        apiStore.reset();
      }
      apiStore.get();
    } else {
      apiStore.reset();
    }
  };

  argsStore.subscribe((res) => {
    const newArgs = argsTransform(res);
    if (newArgs && args && JSON.stringify(newArgs) === JSON.stringify(args))
      return;
    args = JSON.parse(JSON.stringify(newArgs));
    getOrReset();
  });

  const subscribers = new Set();

  const subscribe = (subscriber) => {
    subscribers.add(subscriber);

    subscriber(data);
    return () => subscribers.delete(subscriber);
  };

  apiStore.subscribe((res) => {
    data = dataTransform(res);
    subscribers.forEach((subscriber) => subscriber(data));
  });

  const reset = () => {
    apiStore.reset();
    data = init();
    subscribers.forEach((subscriber) => subscriber(data));
  };

  return { subscribe, reset, meta: apiStore.meta, get: getOrReset };
};

export const SwArgsApiStore = ({
  get,
  init,
  onMessage,
  dataTransform = (x) => x,
  resetBeforeFetch = true,
  concurrencyStrategy = "abortPreviousRequests",
  rateLimitMs = 0,
  debounceDelayMs = 0,
}) => {
  const argsStore = writable(null);
  const store = SwDerivedApiStore({
    get,
    init,
    onMessage,
    dataTransform,
    resetBeforeFetch,
    concurrencyStrategy,
    argsStore,
    rateLimitMs,
    debounceDelayMs,
  });
  return { ...store, set: argsStore.set, update: argsStore.update };
};
